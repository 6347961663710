@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Roboto:wght@100;300;400;500;700&family=Shadows+Into+Light&display=swap');

* {
  overflow-x: hidden;
}

html, body {
  margin: 0;
  width: 100vw;
}

@supports (-webkit-overflow-scrolling: touch) {
  .background-image {
  background-attachment: scroll;
  }
}