#projects {
    background-color: #ebeff0;
    width: 100vw;
    margin-top: 4rem;
}

.projects-heading {
    text-align: left;
    z-index: 1;
    position: relative;
    font-size: 5rem;
    margin-bottom: .5rem;
    margin-left: 3rem;
    color: #222d3e;
    margin-top: 4rem;
    font-family: 'Roboto', sans-serif;
}

.projects-background {
    background-color: #ebeff0;
    background-size: 10px 10px;
    display: inline-block;
    position: relative;
    width: 70%;
    height: 180px;
    margin-top: -8.5rem;
    margin-left: 10rem;
    background-image: repeating-linear-gradient(45deg, #aedbde 0, #aedbde 1px, #ebeff0 0, #ebeff0 50%);
}

.projects-line {
    width: 100px;
    margin-left: 3rem;
    border-radius: 5px;
    height: 4px;
    background: #78c4c8;
    margin-top: -2.5rem;
}

.projects-scroll {
   font-family: 'League Spartan', sans-serif;
   letter-spacing: 3px;
   margin-left: 13rem;
   margin-top: 9rem;
   text-transform: uppercase;
   font-size: 11px;
}

.projects-scroll-line {
    height: 150px;
    border-left: .5px solid #222d3e;
    place-self: center;
    margin-left: 1.5rem;
    margin-top: .5rem;
}

.code-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -10rem;
    margin-left: 37rem;
}

.code {
    position: relative;
    width: 200px;
    display: inline-flex;
    font-size: 5rem;
    font-family: 'Roboto', sans-serif;
    color: #78c4c8;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 15rem;
}

.projects-grid img {
    display: flex;
    margin: auto;
    width: 60%;
    border: 1px none transparent;
    border-radius: 4px;
    box-shadow: 8px 8px 22px -15px rgba(0,0,0,0.75);
}

.projects-grid h3 {
    font-family: 'Roboto';
    font-size: 2.5rem;
    color:#222d3e;
    margin: auto;
    text-align: center;   
}

.highlights {
    position: absolute;
    background-color: #78c4c8;
    top: 55%;
    left: 1%;
    bottom: auto;
    height: 36%;
    width: 100%;
    z-index: -1;   
}

.underline {
    display: inline;
    position: relative;
    z-index: 1;
    color: #222d3e;
}

.project-description {
    padding-top: 1rem;
    padding: .5rem;
}

.code-info {
    display: flex;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
}

.code-info a {
    color: #222d3e;
    text-decoration: none;
    font-size: .8rem;
    padding-left: .5rem;
    padding-right: .5rem;
}

.code-info a:hover {
    color: #78c4c8;
}

.project-one {
    grid-area: 1/1;
}

.project-one-info {
    grid-area: 1/2; 
    width: 90%;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans';
    font-size: 1rem;
    letter-spacing: .5px;
    color:#222d3e;
}

.project-two {
    grid-area: 2/1;
    margin-top: 5rem;
}

.project-two-info {
    grid-area: 2/2; 
    width: 90%;
    font-family: 'Open Sans';
    font-size: 1rem;
    letter-spacing: .5px;
    color:#222d3e;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;

}

.project-three {
    grid-area: 3/1;
    place-self: center;
    margin-top: 5rem;
    margin-bottom: 5rem; 
}

.project-three-info {
    grid-area: 3/2;
    width: 90%;
    font-family: 'Open Sans';
    font-size: 1rem;
    letter-spacing: .5px;
    color:#222d3e;
    margin-top: 5rem;
    align-items: center;
    justify-content: center;
}


.project-one, .project-two, .project-three {
    transition-duration: 1s;   
}

.project-one:hover, .project-two:hover, .project-three:hover {
    scale: 1.02;
}

.show-more {
    margin-left: 4rem;
}

.show-more button {
    background: transparent;
    border: none;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-size: 1.7rem;
    color: #222d3e;
}

.show-more p {
    margin-left: 4rem;  
}

.show-more p a {
    color: #78c4c8;
    font-size: 1.4rem;
    font-weight: bolder;
    letter-spacing: .5px;
    text-decoration:none;
    font-family: 'Open Sans', sans-serif;
}

.show-more p a:hover {
    color: #222d3e;
}


/*-- MEDIA QUERIES --*/

/*--Small/Medium Screens--*/
@media only screen and (min-width:576px) and (max-width: 945px){
    .projects-scroll {
        margin-left: 10rem;
    }

    .code-container {
        margin-left: 26.5rem;
    }

    .projects-grid img {
        width: 85%;
    }

    .project-two-info {
        margin-top: 7rem;
      
    }

    .project-three-info {
        margin-top: 7rem;
    }

    .projects-grid h3 {
        font-size: 1.7rem;
    }

    .show-more {
        margin-left: 2rem
    }
    
    .show-more button {
        font-size: 1.5rem;
    }

    .show-more p a {
        font-size: 1.3rem;
    }
}

/*--Extra Small/Small Screens--*/
@media only screen and (min-width: 300px) and (max-width: 575px) {
    
    .projects-background {
        margin-left: 6rem;
    }

    .projects-heading {
        font-size: 4.3rem;
    }

    .projects-line {
        margin-top: -2.5rem;
    }

    .projects-scroll {
        margin-left: 3rem;
        margin-top: 10rem;
        margin-bottom: 1rem;
    }

    .projects-scroll-line {
        height: 150px;
    }

    .code-container {
        margin-left: 12rem;
        margin-top: -15rem;
    }

    .projects-grid {
        display: block;
    }

    .projects-grid h3 {
        font-size: 1.7rem;
    }

    .projects-grid img {
        width: 100%;
    }

    .project-one, .project-two, .project-three {
      align-items: center;
      margin: 2rem;
    }

    .project-one-info, .project-two-info, .project-three-info {
        text-align: center;
        margin: 0 auto;
    }

    .project-three-info {
        margin-bottom: 3rem;
    }

    #more-projects {
        margin: 0 auto;
        display: flex;  
    }

    .show-more {
       margin: 0 auto; 
    }

    .show-more button {
        font-size: 1.3rem;
    }

    .show-more p {
        margin-left: 1rem;
    }

    .show-more p a {
        font-size: 1rem;
    } 
}
